import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiTrash2, FiEdit3, FiDollarSign } from 'react-icons/fi';
import dateformat from 'dateformat';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext, MixPanelContext, ModalContext } from '~/contexts';

// Components
import FormBills from '~/pages/Bills/Form';

// Images
import icon from './images/icon.svg';
import ReactTooltip from 'react-tooltip';
import { RiLoader2Line } from 'react-icons/ri';

interface Props {
    id      : number;
    provider: string;
    modality: string;
    date    : string;
    status  : string;
    path    : string;
    earq_id : string;
    signed  : boolean;
    setReady: Function;
    delBill : Function;
    bill    : any;
}

const BillBox: React.FC<Props> = ({id, provider, modality, date, status, path, earq_id, delBill, setReady, bill}: Props) => {
    const {user, unit}      = useContext(GeneralContext);
    const {buttonClick}     = useContext(MixPanelContext);
    const Modal             = useContext(ModalContext);
    const [ecoUp, setEcoUp] = useState<boolean>(false);

    function openModal (modal: string) {
        Modal.setModalOpen(false);
        Modal.setModalClass('mbills');

        switch (modal) {
            case 'edit':
                Modal.setModalTitle('Editando Conta de Energia')
                //Modal.setModalBody(<FormBills setReady={setReady} billDataEdit={bill}/>)
                Modal.setModalBody(<FormBills setReady={setReady} bill={bill}/>)
                Modal.setModalOpen(true)
                break;

            case 'delete':
                Modal.setModalClass('delete')
                Modal.setModalTitle('Excluindo Conta de Energia')
                Modal.setModalBody(<>
                    <div>Deseja realmente excluir a conta referente a <i>"{ date }"?</i></div>
                    <div className="alert">* Tenha cuidado, esse processo é irreversível!</div>

                    <footer>
                        <button id="btnDelete" type="button" onClick={() => delBill(id)}>Excluir</button>
                        <button id="btnCancel" type="button" onClick={() => Modal.setModalOpen(false)}>Cancelar</button>
                    </footer>
                </>)

                // delBill(id)
                Modal.setModalOpen(true)
                break;

            default:
                Modal.setModalClass(null);
                Modal.setModalTitle('');
                Modal.setModalBody(<></>);
                break;
        }
    }

    async function reloadEconomy () {
        setEcoUp(true);

        await api.get(`scripts/economy/update?unit=${unit.id}&date=${dateformat(date, 'yyyy-mm')}`, {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            setEcoUp(false);
        }).catch(err => {
            console.log(err);
        });
    }

    function downloadFile () {
        buttonClick(user.id, 'Contas de energia', `Download de fatura`)

        api.get(`bill/${path}`, {
            headers: { Authorization: user.token },
            responseType: 'blob'
        }).then((resp: any) => {
            let url      = window.URL.createObjectURL(new Blob([resp.data]));
            let link     = document.createElement('a');
            let fileName = `${unit ? `Conta - ${unit.name}` : 'Conta'}_${dateformat(date, 'mm-yyyy')}.pdf`;

            link.href = url;
            link.setAttribute("id", "download");
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        }).catch(err => {
            console.log(err);
        });
    }

    return <div className='billBox'>
        <div className="icon">
            <img src={icon} alt="Icon" />
        </div>

        <div className="infos">
            {
                status === 'Read' ? <>
                    <div className="provider">
                        <small>Distribuidora</small>
                        <span>{provider.toUpperCase()}</span>
                    </div>

                    <div className="modality">
                        <small>Modalidade</small>
                        <span>{modality}</span>
                    </div>

                    <div className="date">
                        <small>Data da Conta</small>
                        <span>{dateformat(date, 'mm.yyyy')}</span>
                    </div>
                </> : <div className="waiting">
                        <span><b>Aguardando Leitura</b><i>{ earq_id ? ` (${earq_id.substring(4) })` : '' }</i></span>
                </div>
            }
        </div>

        <div className="buttons">
            {
                path!==null ? <Link to="/contas" className='download' onClick={() => downloadFile()}>
                    Download
                </Link> : <button className='download' disabled>
                    Download
                </button>
            }

            { user.role !== 'client' &&
                <button
                    type="button"
                    className={`reloadEconomy${ecoUp ? ' load' : ''}`}
                    onClick={() => reloadEconomy()}
                    data-for="tooltip_bills_box"
                    data-tip="Atualizar Economia"
                    disabled={status !== 'Read' ? true : ecoUp}
                >
                    {
                        !ecoUp ?
                            <FiDollarSign /> :
                            <RiLoader2Line color="#FFFFFF" />
                    }
                </button>
            }

            { user.role !== 'client' &&
                <button
                    type="button"
                    className='edit'
                    onClick={() => openModal('edit')}
                    data-for="tooltip_bills_box"
                    data-tip="Editar Conta de Energia"
                    disabled={status === 'Read' ? false : true}
                >
                    <FiEdit3 />
                </button>
            }

            <button
                type="button"
                className='del'
                onClick={() => delBill && openModal('delete')}
                data-for="tooltip_bills_box"
                data-tip="Excluir Conta de Energia"
                disabled={status === 'Read' ? false : true}
            >
                <FiTrash2 />
            </button>
        </div>

        <ReactTooltip
            id="tooltip_bills_box"
            place="left"
            effect="solid"
            type="info"
        />
    </div>;
}

export default BillBox;