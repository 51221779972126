import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaTachometerAlt, FaBook, FaFolder, FaSignOutAlt, FaUsers, FaEnvelope, FaLayerGroup, FaAddressBook, FaUnlock, FaFileAlt, FaFileInvoiceDollar } from 'react-icons/fa';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Get Version
const version = require('~/../package.json').version;

const Menu: React.FC = () => {
    const {user} = useContext(GeneralContext);
    const location = useLocation();

    return <aside>
        <nav>
            <Link to="/" className={location.pathname === '/' || location.pathname.indexOf('/ref/')>=0 ? ' active' : ''}>
                <FaTachometerAlt />
                <span>Dashboard</span>
            </Link>

            <Link to="/relatorio" className={location.pathname.indexOf('relatorio')>=0 ? ' active' : ''}>
                <FaBook />
                <span>Relatório de Consumo</span>
            </Link>

            <Link to="/contas" className={location.pathname.indexOf('contas')>=0 ? ' active' : ''}>
                <FaFileAlt />
                <span>Contas de Energia</span>
            </Link>

            <Link to="/documentos" className={location.pathname.indexOf('documentos')>=0 ? ' active' : ''}>
                <FaFolder />
                <span>Documentos</span>
            </Link>

            {
                user.role!=='admin' && <Link to="/emails/cadastro" className={location.pathname.indexOf('emails/cadastro')>=0 ? ' active' : ''}>
                    <FaEnvelope />
                    <span>Gerenciador de Emails</span>
                </Link>
            }

            {
                user.role==='admin' ? <>
                    <hr />

                    <Link to="/admin/grupos" className={location.pathname.indexOf('admin/grupos')>=0 ? ' active' : ''}>
                        <FaLayerGroup />
                        <span>Grupos</span>
                    </Link>

                    <Link to="/admin/clientes" className={location.pathname.indexOf('admin/clientes')>=0 ? ' active' : ''}>
                        <FaAddressBook />
                        <span>Unidades Consumidoras</span>
                    </Link>

                    <Link to="/admin/permissoes" className={location.pathname.indexOf('admin/permissoes')>=0 ? ' active' : ''}>
                        <FaUnlock />
                        <span>Permissões</span>
                    </Link>

                    <Link to="/admin/s/helpAdmin" className={location.pathname.indexOf('s/helpAdmin')>=0 ? ' active' : ''}>
                    <FaFileInvoiceDollar />
                        <span>Solicitações de leitura</span>
                    </Link>

                    <Link to="/admin/emails/cadastro" className={location.pathname.indexOf('admin/emails')>=0 ? ' active' : ''}>
                        <FaEnvelope />
                        <span>Gerenciador de Emails</span>
                    </Link>

                    {
                        location.pathname.indexOf('admin/emails')>=0 && <div className='submenu'>
                            <Link to="/admin/emails/cadastro" className={location.pathname.indexOf('admin/emails/cadastro')>=0 ? ' active' : ''}>
                                <FaEnvelope />
                                <span>Cadastro de Emails</span>
                            </Link>

                            <Link to="/admin/emails/logs" className={location.pathname.indexOf('admin/emails/logs')>=0 ? ' active' : ''}>
                                <FaEnvelope />
                                <span>Envios e Logs</span>
                            </Link>
                        </div>
                    }

                    <Link to="/admin/usuarios" className={location.pathname.indexOf('admin/usuarios')>=0 ? ' active' : ''}>
                        <FaUsers />
                        <span>Usuários</span>
                    </Link>
                </> : <></>
            }
        </nav>

        <footer>
            <div>
                <Link to="/logout" className='logout'>
                    <FaSignOutAlt />
                </Link>
            </div>

            <span>Version {version}</span>
        </footer>
    </aside>;
}

export default Menu;