import React, { ChangeEvent, useEffect, useState } from 'react';
import './styles.css';

interface Props {
    id     : number;
    name   : string;
    mails  : any[];
    setMail: Function;
}

const Box: React.FC<Props> = (props) => {
    const [checked, setChecked] = useState(false);

    function onChange (event: ChangeEvent<HTMLInputElement>) {
        let checked   = event.currentTarget.checked
        let mailsList = JSON.parse(JSON.stringify(props.mails))

        if (checked) {
            mailsList.push({
                id: props.id,
                name: props.name
            })
            props.setMail(mailsList)
            setChecked(true)
        } else {
            let index = mailsList.findIndex((q: any) => q.id===props.id)
            mailsList.splice(index, 1);
            props.setMail(mailsList)
            setChecked(false)
        }
    }

    useEffect(() => {
        setChecked(false)
        props.mails.filter(el => el.id===props.id && setChecked(true))
    }, [props.mails])

    return (
        <div className='mailer_line'>
            <span className='sel'><input type="checkbox" value={props.id} onChange={onChange} checked={checked} /></span>
            <span className='unit'>{props.name}</span>
        </div>
    )
}

export default Box;