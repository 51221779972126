import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import { MdAttachEmail } from 'react-icons/md';
import api from '~/services/api';
import calc from '~/services/calculate';
import dates from '~/utils/dates';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Admin from '~/components/Admin';
import Loading from '~/components/Default/Loading';
import EconomyChart from '~/components/Charts/Economy';
import EconomyBox from '~/components/Boxs/EconomyLevel';
import CalendarBox from '~/components/Boxs/Calendar';

// Images
import graphicIcon from '~/assets/images/graphic-icon.png';
import graphicIcon2 from '~/assets/images/graphic-icon2.png';
import numbers from '~/utils/numbers';
import { MixPanelContext } from '~/contexts/mixPanel';
import { RiFileExcel2Fill } from 'react-icons/ri';
import * as XLSX from 'xlsx';

interface FormattedData {
  hora: string;
  data:string
  consumoKWh: number;
  unidade:string;
}

interface Time {
    date: string;
    time: string;
    consKWh: number;
    // Inclua outros campos relevantes de acordo com seus dados
  }

  interface Day {
    day: number;
    times: Time[];
  }

const Dashboard: React.FC<any> = () => {
    const navigate                      = useNavigate();
    let params                          = useParams();
	let yearMonth: any                  = params.yearMonth;
    const monthsSelect                  = dates.getMonthsYear();
    const date                          = new Date();

    // Context
    const {user, unit, getUnit}         = useContext(GeneralContext);
    const {buttonClick}   = useContext(MixPanelContext);


    // States
    const [ready, setReady]             = useState<Boolean>();
    const [data, setData]               = useState<any>(null);
    const [consumption, setConsumption] = useState<any>(null);
    const [months, setMonths]           = useState(6);
    const [ymSel, setYearMonth]         = useState<number[] | null>(yearMonth ? yearMonth.split('-') : null);

    // Check Provider

    function changeMonth(event: any) {
        let value = event.target.value;

        if (value!=='') {
            let yearMonth = value.split('-');
            buttonClick(user.id, 'Dashboard', `Seletor de mês de referência ${yearMonth[0]}-${yearMonth[1]}`)

            if (Number(yearMonth[0])===date.getUTCFullYear() && Number(yearMonth[1])===(date.getUTCMonth()+1)) {
                navigate('/')
                yearMonth = null;
            } else {
                navigate(`/ref/${yearMonth[0]}-${yearMonth[1]}`)
            }

            setYearMonth(yearMonth);
            setReady(false);
        }
    }

    function getPeriodTotal() {
        let totalArr: any = ['0', '00'];

        if (data && data.economy) {
            switch (months) {
                case 6:
                    totalArr = numbers.money(data.economy.periods.semester.total, false).split(',');
                    break;
                case 3:
                    totalArr = numbers.money(data.economy.periods.quarter.total, false).split(',');
                    break;

                default:
                    totalArr = numbers.money(data.economy.periods.yearly.total, false).split(',');
                    break;
            }
        }

        return `R$ ${totalArr[0]}<sup>,${totalArr[1]}</sup>`;
    }

    function getLastTotal () {
        let lastArr = numbers.money(data.economy.last.total, false).split(',');
        return `R$ ${lastArr[0]}<sup>,${lastArr[1]}</sup>`;
    }

    function getNameSelectedMonth() {
        return dates._getNameMonth(!ymSel ? date.getUTCMonth() : (ymSel[1]-1)).toLowerCase();
    }

    function getData() {
        !ready && api.get(`clients/me2${ unit ? `/${unit?.id}` : ''}${ yearMonth ? `/${yearMonth}` : ''}`, {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            setData(resp.data);
            setConsumption(resp.data.consumption);
            setReady(true);
        }).catch(err => {
            console.log(err);
        });
    }

    function formDataExcel(unitName:any, data: Day[], info:any){
        const date = new Date(info[0].times[0].date);
        const month = date.getUTCMonth() + 1; // Adiciona 1 porque getUTCMonth() retorna 0-11


        const formattedData = data.flatMap(dayObj => {
            return dayObj.times.map((timeObj:any) => {
              const date = new Date(timeObj.date);
              const [hourString] = timeObj.time.split(':'); // Extraindo a hora diretamente do campo 'time'
              const hour = parseInt(hourString, 10);
              const day = date.getUTCDate();
              const month = date.getUTCMonth() + 1; // getUTCMonth() retorna 0-11, então somamos 1
              const consKWh = timeObj.consKWh;
              const description = timeObj.status.name;
              const unit = unitName.consumption.name;

              return { unidade: unit, hora:`${hour}:00`, data:`${day>9?day:`0${day}`}/${month>9?month:`0${month}`}`, consumoKWh:consKWh, descrição:description };
            });
          });
          //console.log("🚀 ~ formDataExcel ~ formattedData:", formattedData)
          exportToExcel(formattedData, `Energizou-Consumo${month>9?month:`0${month}`}`)
    }


    function exportToExcel(data: FormattedData[], fileName: string) {

        // Passo 1: Criar uma nova planilha
        const worksheet = XLSX.utils.json_to_sheet(data);

        // Passo 2: Criar uma nova pasta de trabalho
        const workbook = XLSX.utils.book_new();

        // Passo 3: Adicionar a planilha à pasta de trabalho
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados');

        // Passo 4: Gerar o arquivo Excel e fazer o download
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
      }

    useEffect(() => {
        if (user.role === "admin" && !unit) getUnit()
        else getData()
    }, [user, unit, ready]);

    return <Admin pageTitle="Dashboard" title="Dashboard" classMain='dash'>
        {
            ready ?
                unit ? (
                    <div className="lineBox">
                        <div className='left'>
                            <div className='selectors'>
                                <div className='title'>
                                    <h1>{unit.name}</h1>
                                    <p>Cliente desde: {unit ? dates.getDateFormatted(unit.init_contract, 'dd/mm/yyyy') : '-'}</p>
                                </div>

                                <div>
                                    <div className='monthSelectBox'>
                                        <p>Seletor de período</p>
                                        <div>
                                            <button type="button" className={months===3 ? 'active'  : ''} onClick={() => {
                                                buttonClick(user.id, 'Dashboard', 'Seletor de período alterado para 3 meses')
                                                setMonths(3)}}>3M</button>
                                            <button type="button" className={months===6 ? 'active'  : ''} onClick={() => {
                                                setMonths(6)
                                                buttonClick(user.id, 'Dashboard', 'Seletor de período alterado para 6 meses')
                                                }}>6M</button>
                                            <button type="button" className={months===12 ? 'active' : ''} onClick={() => {
                                                setMonths(12)
                                                buttonClick(user.id, 'Dashboard', 'Seletor de período alterado para 12 meses')}}>12M</button>
                                        </div>
                                    </div>

                                    <div className="monthsYearsBox">
                                        <p>Mês de referência</p>
                                        <select name="month" id="month" onChange={changeMonth} value={yearMonth ? yearMonth : date.getUTCFullYear() + '-' + (date.getUTCMonth()+1)}>
                                            <optgroup label="Mês / Ano">
                                                {
                                                    monthsSelect.map(el => {
                                                        return (
                                                            <option key={`mes-ano_${el.year + '-' + el.month}`} value={ el.year + '-' + el.month }>
                                                                { el.name + ' de ' + el.year }
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </optgroup>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className='cards'>
                                <div className='card-box period'>
                                    <div>
                                        <span><b>Nesse período,</b> você obteve</span>
                                        <FaInfoCircle color='#fff'/>
                                    </div>
                                    <p dangerouslySetInnerHTML={{__html: getPeriodTotal()}} />
                                    <sub>Em economia</sub>
                                </div>

                                <div className='card-box'>
                                    {
                                        data && data.economy && data.economy.last ? <>
                                            <div>
                                                <span>Sua economia em {data.economy.last.month} foi</span>
                                                <FaInfoCircle/>
                                            </div>

                                            <p dangerouslySetInnerHTML={{__html: getLastTotal()}} />
                                            <sub>Em economia</sub>
                                        </> : <>
                                            <div>No momento não há economia computada para o mês referênciado</div>
                                        </>
                                    }
                                </div>

                                <div className="card-graphic">
                                    <div>
                                        <img src={graphicIcon} alt='previsão de consumo'></img>
                                        <FaInfoCircle/>
                                    </div>
                                    <span>{ !ymSel ? (consumption && consumption.consumption ? calc.prevConsMWh(consumption.consumption, true) : 0) : (consumption && consumption.consumption ? calc.consMWh(consumption.consumption.last, true) : 0) } MWh</span>
                                    <p>{ !ymSel ? 'Previsão de consumo no mês' : `Consumo de ${ getNameSelectedMonth() } de ${ consumption.labels.last }` }</p>
                                </div>

                                <div className="card-graphic">
                                    <div>
                                        <img src={graphicIcon2} alt='consumo acumulado'></img>
                                        <FaInfoCircle/>
                                    </div>
                                    <span>{consumption && consumption.consumption ? calc.consMWh(consumption.consumption.current, true) : 0} MWh</span>
                                    <p>{ !ymSel ? `Consumo acumulado até o dia ${new Date().getDate()-1}` : `Consumo mês de ${ getNameSelectedMonth() } de ${ consumption.labels.current }` }</p>
                                </div>
                            </div>

                            <div className='chart'>
                                <EconomyChart months={months} infos={data && data.economy ? data.economy.periods: null}/>
                            </div>
                        </div>

                        <div className='right'>
                            <EconomyBox economy={data && data.economy ? data.economy.total : null} reference={consumption ? consumption.cost_reference : null} />
                            <CalendarBox yearMonth={ymSel ? ymSel : null} consumptions={consumption?.consDays} formDataExcel={() => formDataExcel(data, consumption?.consDays, consumption?.consDays)} />
                        </div>
                    </div>
                ) : (
                    <div className="info-center">
                        <p className="info">A dashboard para a sua distribuidora não está disponível para visualização no sistema.</p>
                        <p className="info">Fique tranquilo, pois você receberá esse relatório por e-mail em breve.</p>
                        <MdAttachEmail />
                    </div>
                ) :
            <div className="alert"><Loading /></div>
        }
    </Admin>
}

export default Dashboard;